<template>
    <div class="container">
        <div class="main">
            <div class="main-title">
                <span>用户登陆</span>
            </div>
            <div class="main-content">
                <div class="main-content-box">
                    <span>重置密码</span>
                    <span class="main-content-buttom"></span>
                </div>
                <div class="main-login-form">
                    <el-form :model="formData" :rules="rules" ref="ruleForm">
                        <el-form-item prop="mobile" :show-message="false">
                            <el-input v-model="formData.mobile" placeholder="手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="password" :show-message="false">
                            <el-input type="password" v-model="formData.password" placeholder="新密码"></el-input>
                        </el-form-item>
						<el-form-item  prop="vercode" v-if="shop_pc_reception_login == 1" :show-message="false">
							<el-input autocomplete="off" v-model="formData.vercode" placeholder="验证码" maxlength="4"></el-input>
							<span class="right_part">
								<img :src="captcha.img" mode class="captcha" @click="getCaptcha" />
							</span>
						</el-form-item>
                        <el-form-item prop="dynacode" style="position: relative;" :show-message="false">
                            <el-input v-model="formData.dynacode" placeholder="动态码"></el-input>
                            <span class="right_part"  @click="sendMobileCode('ruleForm')">{{ dynacodeData.codeText }}</span>
                        </el-form-item>
                        <el-form-item class="main-login-form-button">
                            <el-button @click="resetPass('ruleForm')">提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { registerConfig, smsCode, nextStep, rePass } from "@/api/auth/login"
import { captchaConfig } from "@/api/website"
import { captcha } from "@/api/website"
export default {
    data() {
        var isMobile = (rule, value, callback) => {
        	if (!value) {
        		return callback(new Error("手机号不能为空"))
        	} else {
        		const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
        
        		if (reg.test(value)) {
        			callback()
        		} else {
        			callback(new Error("请输入正确的手机号"))
        		}
        	}
        }
        
        let self = this
        var setPass = function(rule, value, callback) {
        	let regConfig = self.registerConfig
        	if (!value) {
        		return callback(new Error("请输入新的登录密码"))
        	} else {
        		if (regConfig.pwd_len > 0) {
        			if (value.length < regConfig.pwd_len) {
        				return callback(new Error("密码长度不能小于" + regConfig.pwd_len + "位"))
        			}
        		}
				if (regConfig.pwd_complexity != "") {
					let passwordErrorMsg = "密码需包含",
						reg = ""
					if (regConfig.pwd_complexity.indexOf("number") != -1) {
						reg += "(?=.*?[0-9])"
						passwordErrorMsg += "数字"
					} 
					if (regConfig.pwd_complexity.indexOf("letter") != -1) {
						reg += "(?=.*?[a-z])"
						passwordErrorMsg += "、小写字母"
					} 
					if (regConfig.pwd_complexity.indexOf("upper_case") != -1) {
						reg += "(?=.*?[A-Z])"
						passwordErrorMsg += "、大写字母"
					} 
					if (regConfig.pwd_complexity.indexOf("symbol") != -1) {
						reg += "(?=.*?[#?!@$%^&*-])"
						passwordErrorMsg += "、特殊字符"
					} 
					if (new RegExp(reg).test(value)) {
						callback();
					} else {
						return callback(new Error(passwordErrorMsg))
					}
				}else{
					callback();
				}
        	}
        }
        return {
			shop_pc_reception_login:0,
			registerConfig: {},
			dynacodeData: {
				seconds: 30,
				timer: null,
				codeText: "获取动态码",
				isSend: false
			}, // 动态码
            formData: {
                mobile: '',
                vercode: '',
				dynacode: "",
                password: '',
				key:'',
            },
			captcha: {
				id: "",
				img: ""
			}, // 验证码
            rules: {
                mobile: [{
                    required: true,
                    validator: isMobile,
                    trigger: "blur"
                }],
                password: [{
                    required: true,
                    trigger: "blur",
					validator: setPass,
                }],
                vercode: [{
                    required: true,
                    message: "请输入验证码",
                    trigger: "blur"
                }],
				dynacode: [{
					required: true,
					message: "请输入短信动态码",
					trigger: "blur"
				}]
            }
        }
    },
	created() {
		this.getCaptcha();
		this.getRegisterConfig();
		this.getCaptchaConfig();
	},
	watch: {
		"dynacodeData.seconds": {
			handler(newValue, oldValue) {
				if (newValue == 0) {
					clearInterval(this.dynacodeData.timer)
					this.dynacodeData = {
						seconds: 30,
						timer: null,
						codeText: "获取动态码",
						isSend: false
					}
				}
			},
			immediate: true,
			deep: true
		}
	},
    methods:{
		getCaptchaConfig() {
			captchaConfig()
			.then(res => {
				if(res.code >= 0) {
					this.shop_pc_reception_login = res.data.value.shop_pc_reception_login
				}
			})
			.catch(err => {
				this.$message.error(err.message)
			})
		},
		resetPass(formName){
			this.$refs[formName].validate((valid,fun) => {
				if (valid) {
					rePass({
							password: this.formData.password,
							code: this.formData.dynacode,
							key: this.formData.key,
							mobile: this.formData.mobile,
						})
						.then(res => {
							if (res.code >= 0) {
								this.$message({
									message: '重置成功',
									type: "success"
								})
								this.$router.push('/login')
							}
						})
						.catch(err => {
							this.$message.error(err.message)
						})
				} else {
					var fail = [];
					for (let key in fun) {
						fail.push(fun[key][0].message);
					}
					this.$message.error(fail[0]);
					return false;
				}
			})
		},
		sendMobileCode(formName) {
			if (this.dynacodeData.seconds != 30) return
			this.$refs[formName].clearValidate("dynacode")
			this.$refs[formName].validateField("mobile", valid => {
				if (valid) {
					this.$message.error(valid);
					return false;
				}else{
					nextStep({
							mobile: this.formData.mobile
						})
						.then(res => {
							if (res.code == -1) {
								if(this.shop_pc_reception_login == 1){
									this.$refs[formName].validateField("vercode", valid => {
										if (!valid) {
											if (this.isSend) return
											this.isSend = true
											smsCode({
													captcha_id: this.captcha.id,
													captcha_code: this.formData.vercode,
													mobile: this.formData.mobile
												})
												.then(res => {
													if (res.code >= 0) {
														this.formData.key = res.data.key
											
														if (this.dynacodeData.seconds == 30 && this.dynacodeData.timer == null) {
															this.dynacodeData.timer = setInterval(() => {
																this.dynacodeData.seconds--
																this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
															}, 1000)
														} else {
															this.$message({
																message: res.message,
																type: "warning"
															})
															this.isSend = false
															this.getCaptcha()
														}
													}
												})
												.catch(err => {
													this.isSend = false
													this.getCaptcha()
													this.$message.error(err.message)
												})
										} else {
											return false
										}
									})
								}else{
									if (this.isSend) return
									this.isSend = true
									
									smsCode({
											captcha_id: this.captcha.id,
											captcha_code: this.formData.vercode,
											mobile: this.formData.mobile
										})
										.then(res => {
											if (res.code >= 0) {
												this.formData.key = res.data.key
									
												if (this.dynacodeData.seconds == 30 && this.dynacodeData.timer == null) {
													this.dynacodeData.timer = setInterval(() => {
														this.dynacodeData.seconds--
														this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
													}, 1000)
												} else {
													this.$message({
														message: res.message,
														type: "warning"
													})
													this.isSend = false
													this.getCaptcha()
												}
											}
										})
										.catch(err => {
											this.isSend = false
											this.getCaptcha()
											this.$message.error(err.message)
										})
								}
								
							} else {
								this.$message({
									message: res.message,
									type: "warning"
								})
							}
						})
						.catch(err => {
							if (err.code == 0) {
								this.$message({
									message: "该手机号未注册",
									type: "warning"
								})
							} else {
								this.$message.error(err.message)
							}
						})
					
				}
			})
			
		},
        getCaptcha() {
        	captcha({
        			captcha_id: this.captcha.id
        		})
        		.then(res => {
        			if (res.code >= 0) {
        				this.captcha.id = res.data.id
        				this.captcha.img = res.data.img
        				this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
        			}
        		})
        		.catch(err => {
        			this.$message.error(err.message)
        		})
        },
		getRegisterConfig() {
			registerConfig()
				.then(res => {
					if (res.code >= 0) {
						this.registerConfig = res.data.value
					}
				})
				.catch(err => {
					console.log(err.message)
				})
		}
    }
}
</script>

<style lang="scss" scoped>
.right_part{
	position: absolute;
	right: 10px;
	top: 50%;
	color: #30373d;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 13px;
	line-height: 15px;
	a{
		font-size: 13px;
		line-height: 15px;
		color: #30373d !important;
	}
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
	height: calc( 100vh - 43px );
    background-image: url("../../../assets/images/newImg/login_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .main {
        width: 554px;
		display: flex;
		flex-direction: column;
        .main-title {
			font-size: 38px;
			color: #fff;
			text-align: center;
			font-weight: 800;
			line-height: 53px;
			span{
				margin-right: 20px;
				&:last-child{
					margin-right: 0;
				}
			}
        }
        .main-content {
			margin-top: 61px;
			padding: 76px 68px 57px;
			border-radius: 10px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
            .main-content-box {
                font-size: 19px;
                font-weight: 500;
                color: #30373D;
                line-height: 23px;
                padding-bottom: 24px;
                text-align: center;
                position: relative;

                .main-content-buttom {
                    position: absolute;
                    width: 34px;
                    height: 3px;
                    border-radius: 2px;
                    background-color: #30373D;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }


            .main-login-form {
                margin-top: 30px;
				:deep(.el-form-item__error){
					padding-left: 15px;
					color: red;
				}
                :deep(.el-input__inner) {
                    border: none;
                    background-color: #F7F8FB;
                    border-radius: 3px;
                    height: 47px;
					font-size: 13px;
					text-align:center;
                    line-height: 47px;
                }

                :deep(.el-input__inner::-webkit-input-placeholder) {
                    color: #999;
                    font-size: 13px;
                }

                :deep(.el-form-item) {
                    margin-bottom: 30px;
                }

                .main-login-form-button {
                    text-align: center;
                    margin-bottom: 0px;
                    background-color: #30373D;
                    border-radius: 3px;
                    margin-top: 51px;
                    .el-button {
						background-color: transparent;
						font-size: 19px;
						line-height: 23px;
						color:#fff;
						width: 100%;
						border: none;
                    }
                }
				.captcha{
					cursor: pointer;
				}
            }
        }
    }
}</style>